import Vue from 'vue'
import VueRouter from 'vue-router'
import InfiniteLoading from 'vue-infinite-loading';
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueLogger from 'vuejs-logger';
import { LOG_LEVEL, ANALYTICS_ENABLED } from './config'

console.log(`${process.env.NODE_ENV} ${process.env.VUE_APP_VERSION} ${LOG_LEVEL}`)

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(InfiniteLoading, {
    props: {
        spinner: 'spiral',
    },
    slots: {
        noMore: '',
    },
});
Vue.use(BootstrapVue)
Vue.use(VueLogger, {
    isEnabled: true,
    logLevel: LOG_LEVEL,
    showLogLevel: true,
    showConsoleColors: true
});

import Amplify, { Analytics } from 'aws-amplify';
import aws_exports from './aws-exports';

Analytics.autoTrack('session', {
    enable: ANALYTICS_ENABLED
});
Analytics.autoTrack('pageView', {
    enable: ANALYTICS_ENABLED,
    type: 'SPA'
});
Analytics.autoTrack('event', {
    enable: ANALYTICS_ENABLED,
});
Amplify.configure(aws_exports);

import App from './App.vue'
import Blog from "./components/Blog.vue";

const router = new VueRouter({
    routes: [
        { path: '/', redirect: '/blog' },
        { path: '/home', redirect: '/' },
        { path: '/blog', component: Blog },
        { path: '/tagged/:keyword', component: Blog },
    ]
})

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
