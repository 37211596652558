/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://tc3sushnqngavduefuayfgyhwi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wrmuqpgvvzamdjm5ocnnlfe4ai",
    "aws_cognito_identity_pool_id": "us-east-1:0061be68-e92c-4330-8778-741872f0fd1f",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "c9e3875593b54fdc8835d26a160a9437",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
