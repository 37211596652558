/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      navItems {
        name
        path
      }
      created
      updated
      version
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        navItems {
          name
          path
        }
        created
        updated
        version
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      created
      updated
      uri
      width
      height
      captured
      title
      caption
      keywords
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        created
        updated
        uri
        width
        height
        captured
        title
        caption
        keywords
      }
      nextToken
    }
  }
`;
export const searchPhotos = /* GraphQL */ `
  query SearchPhotos(
    $filter: SearchablePhotoFilterInput
    $sort: SearchablePhotoSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchPhotos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        created
        updated
        uri
        width
        height
        captured
        title
        caption
        keywords
      }
      nextToken
      total
    }
  }
`;
