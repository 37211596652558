<template>
  <div id="app" class="page">
    <Header :title="title" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    };
  },
};
</script>
