<template>
  <b-card no-body tag="article" class="mb-5">
    <PhotoImage
      :uri="item.uri"
      :version="item.updated"
      :width="item.width"
      :height="item.height"
    />
    <div class="d-flex">
      <div v-if="date" class="text-muted mr-1">{{ date }}</div>
      <div v-if="item.keywords" class="d-flex">
        <div v-for="tag in tags" :key="tag">
          <b-link :to="{ path: `/tagged/${tag}` }" class="tag mr-1">{{
            tag
          }}</b-link>
        </div>
      </div>
    </div>
    <div v-if="caption" class="mt-1">
      <b-card-text>{{ caption }}</b-card-text>
    </div>
  </b-card>
</template>

<script>
import PhotoImage from "./PhotoImage.vue";
import { Analytics } from "aws-amplify";

var logVisit = function (id) {
  Analytics.record({
    name: "photoView",
    attributes: {
      id: id,
    },
  });
};

export default {
  name: "Photo",
  components: {
    PhotoImage,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date: function () {
      const date = this.item.captured;
      if (typeof date !== "string") {
        return null;
      }
      return date.substring(0, date.indexOf("T"));
    },
    caption: function () {
      return this.item.caption;
    },
    tags: function () {
      function checkTag(tag) {
        return typeof tag === "string" && tag.startsWith("_") == false;
      }
      return this.item.keywords.filter(checkTag);
    },
  },
  async created() {
    this.$log.debug(`Photo ${this.item.id} created`);
    logVisit(this.item.id);
  },
};
</script>

<style scoped>
a.tag {
  color: var(--blue);
  text-decoration: underline;
}

a.tag:hover {
  color: var(--white);
  background-color: var(--orange);
  text-decoration: none;
}

a.tag:before {
  content: "#";
}

.card {
  border: 0;
}
</style>