<template>
  <header>
    <b-container>
      <b-navbar toggleable="lg" type="light" class="px-0">
        <b-navbar-brand to="/" v-if="title" class="text-muted">{{
          title
        }}</b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse" />

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav v-if="navItems" class="ml-auto">
            <div v-for="item in navItems" :key="item.name">
              <b-nav-item :to="item.path">
                {{ item.name }}
              </b-nav-item>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
import { API } from "aws-amplify";
import { getConfig } from "./../graphql/queries";

export default {
  name: "Header",
  props: {
    title: String,
  },
  async created() {
    this.getConfig();
  },
  data() {
    return {
      navItems: null,
    };
  },
  methods: {
    async getConfig() {
      const config = await API.graphql({
        query: getConfig,
        variables: {
          id: "default",
        },
      });
      this.navItems = config.data.getConfig.navItems;
    },
  },
};
</script>
