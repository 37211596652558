<template>
  <b-container>
    <div v-for="item in photos" :key="item.id">
      <Photo :item="item" />
    </div>
    <infinite-loading
      :identifier="keyword"
      @infinite="infiniteHandler"
    ></infinite-loading>
  </b-container>
</template>

<script>
import Photo from "./Photo.vue";
import InfiniteLoading from "vue-infinite-loading";
import { API } from "aws-amplify";
import { searchPhotos } from "./../graphql/queries";
import { Analytics } from "aws-amplify";

var logVisit = function (keyword, page) {
  Analytics.record({
    name: "blogView",
    attributes: {
      keyword: keyword,
      page: page,
    },
  });
};

export default {
  name: "Blog",
  components: {
    Photo,
    InfiniteLoading,
  },
  async created() {
    this.$log.debug("Blog created");
    this.keyword = this.$route.params.keyword;
  },
  watch: {
    $route(to) {
      this.photos = [];
      this.page = 0;
      this.nextToken = null;
      this.keyword = to.params.keyword;
    },
  },
  data() {
    return {
      photos: [],
      page: 0,
      nextToken: null,
      keyword: null,
    };
  },
  computed: {
    filter: function () {
      if (typeof this.keyword === "string") {
        const keywords = this.keyword.split(",");
        const reducer = (accumulator, currentValue) => {
          if (accumulator != null) {
            return { keywords: { match: currentValue }, and: accumulator };
          } else {
            return { keywords: { match: currentValue } };
          }
        };
        return keywords.reduce(reducer, null);
      } else {
        // "all" should not include _private
        return { not: { keywords: { match: "_private" } } };
      }
    },
  },
  methods: {
    async getPhotos() {
      this.$log.debug(
        `getPhotos filter: ${JSON.stringify(this.filter)} page: ${
          this.page
        } nextToken: ${this.nextToken}`
      );
      logVisit(this.keyword, this.page);
      const photos = await API.graphql({
        query: searchPhotos,
        variables: {
          limit: 10,
          nextToken: this.nextToken,
          sort: { field: "captured" },
          filter: this.filter,
        },
      });
      this.photos.push(...photos.data.searchPhotos.items);
      this.page = this.page + 1;
      this.nextToken = photos.data.searchPhotos.nextToken;
      return this.nextToken == null;
    },
    async infiniteHandler($state) {
      const complete = await this.getPhotos();
      if (!complete) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
</script>
