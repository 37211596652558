<template>
  <b-img-lazy :src="src" :srcset="srcset" v-bind="mainProps" />
</template>

<script>
import { THUMBNAILER_URL } from "../config";

export default {
  name: "PhotoImage",
  props: {
    uri: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      default: "0",
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    defaultWidth: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: "LightGrey",
        blankWidth: this.defaultWidth,
        blankHeight: Math.round((this.height * this.defaultWidth) / this.width),
        alt: "",
        sizes: "(min-width: 1200px) 1140px, 540px", // TODO: test
      },
      widths: [640, 1280],
    };
  },
  computed: {
    src: function () {
      return this.getImageUrl(this.defaultWidth);
    },
    srcset: function () {
      return this.widths.map((w) => `${this.getImageUrl(w)} ${w}w`).join(", ");
    },
  },
  methods: {
    getImageUrl(longEdge) {
      return `${THUMBNAILER_URL}/thumbnail/${encodeURIComponent(
        this.uri
      )}/long-edge/${longEdge}?ver=${this.version}`;
    },
  },
};
</script>
